import { Header } from './Header';
import { Footer } from './Footer';
import { Body } from './Body';
import { Modal } from './Modal';
import { Theme } from './Theme';
import * as sw3 from '@solana/web3.js';
import flux from '@aust/react-flux';

function Main(props) {
  window.connection = new sw3.Connection(
    'https://twilight-young-forest.solana-mainnet.quiknode.pro/',
  );

  // window.connection = new sw3.Connection(
  //   'https://patient-withered-forest.solana-devnet.quiknode.pro/',
  // );
  window.sw3 = sw3;
  const loading_modal = flux.loading.useState('open');

  return (
    <Theme className="text-white font-sans">
      {loading_modal && (
        <div className="fixed w-full h-full z-10 bg-black opacity-90 text-center flex items-center align-center justify-center">
          <h3>Waiting for Solana</h3>
        </div>
      )}
      <Modal className="" />
      <section className="max-w-6xl container w-auto flex mx-auto flex-col z-10 h-auto min-h-screen">
        <Header className=" rounded-sm bg-opacity-0 h-20 w-full flex items-center select-none" />
        <Body className="mt-4 mb-4" />
        <Footer className="" />
      </section>
    </Theme>
  );
}

function App() {
  return <Main />;
}

export default App;
