import { Img, Button, Loading, Paragraph, A } from '../baseComponents';

export async function amoeboyPage() {
  const paragraphs = [
    {
      Header: 'Amoeboy?',
      Text: 'Amoeboy is a free browser extension that allows you to turn your NFT collection into digital pets. We aim to provide fun and utility for every single person who owns an NFT.',
      Fragment: (
        <p className="mt-4">
          You can find the extension{' '}
          <A href="https://chrome.google.com/webstore/detail/amoeboy/mmclhjiglcbhgmdpimklhmmcnjcadbpm">
            here!
          </A>
        </p>
      ),
    },
  ];

  return (
    <div
      key="1"
      className="m-8 h-full w-full flex flex-row items-center flex-nowrap"
    >
      <div
        key="2"
        className="flex flex-col items-center justify-start max-w-md mx-0"
      >
        {paragraphs.map((e) => {
          return (
            <Paragraph
              className=" border-2 border-opacity-20 bg-transp border-medium  rounded shadow-sm p-4 "
              key={e.header}
              header={e.Header}
              text={e.Text}
              fragment={e.Fragment}
            />
          );
        })}
      </div>
      <div
        key="3"
        className="flex-grow flex flex-col items-center min-h-36 justify-around"
      >
        <Img key="4" name="amoeboyThree" className="max-h-96 flex-grow" />
      </div>
    </div>
  );
}
