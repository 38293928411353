export function cryoPage() {
  //
  return (
    <>
      <div>Meebs</div>
      <div>
        <button>Claim</button>
        Frozen Meebs
      </div>
    </>
  );
}
