import { Button } from '../baseComponents';
import flux from '@aust/react-flux';

export function toolPage() {
  //
  return (
    <>
      <Button
        text="Mass Transfer"
        onClick={() => {
          flux.dispatch('page/change', 'transfer');
        }}
      />
    </>
  );
}
