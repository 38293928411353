import { Loading } from './baseComponents';
import flux from '@aust/react-flux';
import { Gallery } from './Gallery';
import { MarketItem } from './MarketItem';
import {
  UpgradeParentHolder,
  UpgradeDivHolder,
  Amoeboy,
  Upgrades,
} from './Upgrades';
import { useEffect } from 'react';
import { mintAmoeboy } from './web3/mintAmoeboy';
import { getTokenList } from './web3/getTokenList';
import { cryoPage } from './pages/cryoPage';
import { toolPage } from './pages/toolPage';
import { amoeboyPage } from './pages/amoeboyPage';
import { TransferPage } from './pages/transferPage';

export function Body(props) {
  const loading = flux.page.useState('loading');
  const page = flux.page.useState('page');
  const content = flux.page.useState('content');

  useEffect(() => {
    if (loading) {
      loadPage(page);
    }
  }, [loading, page]);

  if (loading) {
    return (
      <div className={props.className + ''}>
        <div className="mt-8">
          <Loading />
        </div>
      </div>
    );
  }
  console.log(content);
  return <div className={props.className + ''}>{content}</div>;
}

async function loadPage(page) {
  let content = await mainPage();
  switch (page) {
    case 'amoeboy':
      content = await amoeboyPage();
      break;
    case 'cryo':
      content = await cryoPage();
      break;
    case 'tools':
      content = await toolPage();
      break;
    case 'transfer':
      content = <TransferPage />;
      break;
    default:
      break;
  }

  console.log(content);
  flux.dispatch('page/content', content);
  flux.dispatch('page/loading', false);
}

function mainPage() {
  //await new Promise(r => setTimeout(r, 2000));

  const UPGRADE = 1;
  const CC = 2;

  const items = [
    {
      name: '404 Frenz',
      image: 'fren' + Math.floor(Math.random() * 6 + 1) + '.gif',
      banner: '404banner.png',
      desc: 'The first upgrades for Amoeboy!',
      qty: 404,
      qty_index: '',
      main: true,
      badges: UPGRADE,
      index: 'uv96taW6QRFFMNH9MpMxfiqK7vWNh7P1v548rHd1sd3',
      p: 5,
    },
    {
      name: 'Sol Street Posse',
      image: 'ssp.gif',
      banner: '404banner.png',
      desc: 'The rocking series produced by Koji!',
      qty: 1000,
      qty_index: '',
      badges: CC,
      main: true,
      index: 'uv96taW6QRFFMNH9MpMxfiqK7vWNh7P1v548rHd1sd3',
      p: 5,
    },
  ];

  return (
    <>
      <div
        key="2"
        className="mt-8 h-auto flex flex-wrap items-center justify-center"
      >
        {items.map((e) => {
          return <MarketItem key={e.name} {...e}></MarketItem>;
        })}
      </div>
    </>
  );
}
