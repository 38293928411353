let Creator = /** @class */ (function () {
  function Creator(args) {
    this.address = args.address;
    this.verified = args.verified;
    this.share = args.share;
  }
  return Creator;
})();
let Data = /** @class */ (function () {
  function Data(args) {
    this.name = args.name;
    this.symbol = args.symbol;
    this.uri = args.uri;
    this.sellerFeeBasisPoints = args.sellerFeeBasisPoints;
    this.creators = args.creators;
  }
  return Data;
})();
let Metadata = /** @class */ (function () {
  function Metadata(args) {
    let _a;
    this.key = 4;
    this.updateAuthority = args.updateAuthority;
    this.mint = args.mint;
    this.data = args.data;
    this.primarySaleHappened = args.primarySaleHappened;
    this.isMutable = args.isMutable;
    this.editionNonce =
      (_a = args.editionNonce) !== null && _a !== void 0 ? _a : null;
  }
  return Metadata;
})();
let METADATA_SCHEMA = new Map([
  [
    Data,
    {
      kind: 'struct',
      fields: [
        ['name', 'string'],
        ['symbol', 'string'],
        ['uri', 'string'],
        ['sellerFeeBasisPoints', 'u16'],
        ['creators', { kind: 'option', type: [Creator] }],
      ],
    },
  ],
  [
    Creator,
    {
      kind: 'struct',
      fields: [
        ['address', [32]],
        ['verified', 'u8'],
        ['share', 'u8'],
      ],
    },
  ],
  [
    Metadata,
    {
      kind: 'struct',
      fields: [
        ['key', 'u8'],
        ['updateAuthority', [32]],
        ['mint', [32]],
        ['data', Data],
        ['primarySaleHappened', 'u8'],
        ['isMutable', 'u8'],
      ],
    },
  ],
]);
// eslint-disable-next-line no-control-regex
let METADATA_REPLACE = new RegExp('\u0000', 'g');
export function decodeMetadata(buffer) {
  let metadata = deserializeUnchecked(METADATA_SCHEMA, Metadata, buffer);
  metadata.data.name = metadata.data.name.replace(METADATA_REPLACE, '');
  metadata.data.uri = metadata.data.uri.replace(METADATA_REPLACE, '');
  metadata.data.symbol = metadata.data.symbol.replace(METADATA_REPLACE, '');
  return metadata;
}

function deserializeUnchecked(schema, classType, buffer, Reader = CReader) {
  const reader = new Reader(buffer);
  return deserializeStruct(schema, classType, reader);
}

function deserializeStruct(schema, classType, reader) {
  if (typeof classType.borshDeserialize === 'function') {
    return classType.borshDeserialize(reader);
  }
  const structSchema = schema.get(classType);
  if (!structSchema) {
    throw new Error(`Class ${classType.name} is missing in schema`);
  }
  if (structSchema.kind === 'struct') {
    const result = {};
    for (const [fieldName, fieldType] of schema.get(classType).fields) {
      result[fieldName] = deserializeField(
        schema,
        fieldName,
        fieldType,
        reader,
      );
    }
    return new classType(result);
  }
  if (structSchema.kind === 'enum') {
    const idx = reader.readU8();
    if (idx >= structSchema.values.length) {
      throw new Error(`Enum index: ${idx} is out of range`);
    }
    const [fieldName, fieldType] = structSchema.values[idx];
    const fieldValue = deserializeField(schema, fieldName, fieldType, reader);
    return new classType({ [fieldName]: fieldValue });
  }
  throw new Error(
    `Unexpected schema kind: ${structSchema.kind} for ${classType.constructor.name}`,
  );
}

const textDecoder = new TextDecoder('utf-8', { fatal: true });

class CReader {
  constructor(buf) {
    this.buf = buf;
    this.offset = 0;
  }
  readU8() {
    const value = [this.buf[this.offset]];
    this.offset += 1;
    return value;
  }
  readU16() {
    const offset = this.offset;
    const value = this.buf[offset] | (this.buf[offset + 1] << 8);

    this.offset += 2;
    return value;
  }
  readU32() {
    const offset = this.offset;
    const value =
      (this.buf[offset] |
        (this.buf[offset + 1] << 8) |
        (this.buf[offset + 2] << 16)) +
      this.buf[offset + 3] * 0x1000000;

    this.offset += 4;
    return value;
  }
  readBuffer(len) {
    if (this.offset + len > this.buf.length) {
      throw new Error(`Expected buffer length ${len} isn't within bounds`);
    }
    const result = this.buf.slice(this.offset, this.offset + len);
    this.offset += len;
    return result;
  }
  readString() {
    const len = this.readU32();
    const buf = this.readBuffer(len);
    try {
      return textDecoder.decode(buf);
    } catch (e) {
      throw new Error(`Error decoding UTF-8 string: ${e}`);
    }
  }
  readFixedArray(len) {
    return new Uint8Array(this.readBuffer(len));
  }
  readArray(fn) {
    const len = this.readU32();
    const result = [];
    for (let i = 0; i < len; ++i) {
      result.push(fn());
    }
    return result;
  }
}

function deserializeField(schema, fieldName, fieldType, reader) {
  try {
    if (typeof fieldType === 'string') {
      return reader[`read${capitalizeFirstLetter(fieldType)}`]();
    }
    if (fieldType instanceof Array) {
      if (typeof fieldType[0] === 'number') {
        return reader.readFixedArray(fieldType[0]);
      } else if (typeof fieldType[1] === 'number') {
        const arr = [];
        for (let i = 0; i < fieldType[1]; i++) {
          arr.push(deserializeField(schema, null, fieldType[0], reader));
        }
        return arr;
      } else {
        return reader.readArray(() =>
          deserializeField(schema, fieldName, fieldType[0], reader),
        );
      }
    }
    if (fieldType.kind === 'option') {
      const option = reader.readU8();
      if (option) {
        return deserializeField(schema, fieldName, fieldType.type, reader);
      }
      return undefined;
    }
    return deserializeStruct(schema, fieldType, reader);
  } catch (error) {
    throw error;
  }
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
