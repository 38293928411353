import React, { useState, useEffect } from 'react';
import { ConnectButton } from './web3/connectButton';

export function Span(props) {
  return (
    <span className={props.className}>
      {props.text}
      {props.children}
    </span>
  );
}

export function SpinLoading(props) {
  const size = props.sz || 12;

  return (
    <svg
      className={props.className + ' mx-auto animate-spin h-5 w-5'}
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-25"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
}

export function Button(props) {
  const logged_in = props.logged_in;
  const require_login = props.require_login || false;

  if (!logged_in && require_login) {
    return <ConnectButton cb={props.onClick} real_text={props.text} />;
  } else {
    return (
      <button
        onClick={props.onClick}
        className={
          props.className +
          ' relative w-fit hover:text-medium transition ul hover:ul ' +
          (props.p !== undefined ? props.p : 'p-4')
        }
      >
        {props.text}
      </button>
    );
  }
}

export function A(props) {
  return (
    <a
      rel="noreferrer"
      className={props.className + ''}
      target="_blank"
      href={props.href}
    >
      {props.children}
    </a>
  );
}

export function Img(props) {
  let name = props.name;
  if (name && !(name.indexOf('.') > -1)) {
    name += '.webp';
  }

  return (
    <img
      onLoad={props.onLoad}
      className={props.className + ''}
      height={props.height && props.height}
      width={props.width && props.width}
      src={props.name ? '/images/' + name : props.src && props.src}
      alt={props.name ? props.name : ''}
    />
  );

  //    return (
  //        <>
  //            {!loaded && <Placeholder />}
  //            <img onLoad={() => { setLoaded(true) }} className={className} src={'/images/' + name + '.webp'} alt={name} />
  //        </>
  //    )
}

//function Placeholder(props) {
//    return <img src='/images/placeholder.webp' alt='placeholder' />
//}

export function Loading(props) {
  let [dot_count, setDot] = useState(1);

  const content = <p>{'.'.padStart(dot_count * 2, ' .')}</p>;

  useEffect(() => {
    let a = setTimeout(() => {
      if (dot_count > 2) {
        setDot(1);
      } else {
        setDot(dot_count + 1);
      }
    }, 450);

    return () => {
      clearTimeout(a);
    };
  });

  return (
    <div className={props.className + '  mx-auto h-32 w-32'}>{content}</div>
  );
}

export function Paragraph(props) {
  return (
    <div className={props.className + ' flex flex-col items-center mt-8'}>
      <h2 className="text-xl text-light underline mb-2 ">{props.header}</h2>
      <span className="max-w-md text-center text-gray-200">
        <p>{props.text}</p>
        {props.fragment}
      </span>
    </div>
  );
}
