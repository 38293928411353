import * as sw3 from '@solana/web3.js';
import { decodeMetadata } from './metaAccount.js';
import flux from '@aust/react-flux';

const amoebit_auth = 'DuNNEyxhzziAq9bQGnUoroCzsQCv43EQuQ7tmieWKRHs';
const meta_program = new sw3.PublicKey(
  'metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s',
);

export async function getTokenList() {
  let token_accounts = await getTokenAccounts(
    window.wallet.publicKey.toBase58(),
  );

  token_accounts = token_accounts.filter((e) => {
    let ta = e.account.data.parsed.info.tokenAmount;
    return ta.amount === '1' && ta.decimals === 0;
  });

  let meta_keys = [];

  for (let i = 0, l = token_accounts.length; i < l; i++) {
    let ta = token_accounts[i].account.data.parsed.info;
    let mint_key = new sw3.PublicKey(ta.mint);

    meta_keys.push(
      (
        await sw3.PublicKey.findProgramAddress(
          [
            new Uint8Array([109, 101, 116, 97, 100, 97, 116, 97]),
            meta_program.toBuffer(),
            mint_key.toBuffer(),
          ],
          meta_program,
        )
      )[0],
    );
  }

  let i = 0;
  let slice = meta_keys.slice(i * 100, (i + 1) * 100);
  let metadata = [];
  while (slice.length > 0) {
    let accounts = await window.connection.getMultipleAccountsInfo(slice);

    accounts = accounts.filter((e) => e !== null);
    accounts.forEach((e) => {
      try {
        metadata.push(decodeMetadata(e.data));
      } catch (e) {}
    });

    i++;
    slice = meta_keys.slice(i * 100, (i + 1) * 100);
  }

  let holder = false;
  metadata.forEach((e) => {
    const sym = e.data.symbol || '';
    if (sym === 'AMBT' || sym === '404' || sym == 'AMBTM') {
      holder = true;
    }
  });

  flux.dispatch('tokens/tokens', metadata);
  flux.dispatch('tokens/holder', holder);
}

async function getTokenAccounts(public_key) {
  let body = JSON.stringify({
    jsonrpc: '2.0',
    id: 1,
    method: 'getTokenAccountsByOwner',
    params: [
      public_key,
      {
        programId: 'TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA',
      },
      {
        encoding: 'jsonParsed',
      },
    ],
  });

  let res = await wrapRPC(body);
  return res.result.value;
}

async function wrapRPC(body) {
  return fetch(window.connection._rpcEndpoint, {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body,
  })
    .then((res) => {
      let rs = res.body,
        reader = rs.getReader();
      return new ReadableStream({
        start(controller) {
          function push() {
            reader.read().then(({ done, value }) => {
              if (done) {
                controller.close();
                return;
              }
              controller.enqueue(value);
              push();
            });
          }

          push();
        },
      });
    })
    .then((stream) => {
      return new Response(stream, {
        headers: { 'Content-Type': 'text/html' },
      }).text();
    })
    .then((streamed_res) => {
      return JSON.parse(streamed_res);
    });
}
