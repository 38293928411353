import { setModal } from './web3/connectButton';
import { useState, useEffect } from 'react';
import { Img } from './baseComponents.js';
import flux from '@aust/react-flux';

export function MarketItem({index, name, image, desc, qty, qty_index, main, p, badges}) {
    const [qty_left, setQty] = useState(null);

    async function updateCount(account) {
        let index  = new window.sw3.PublicKey(account),
        connection = window.connection,
        count = await connection.getAccountInfo(index);
        let bits_0 = (count.data[0]).toString(2),
        bits_1 = (count?.data[1] || 0).toString(2),
        bits_2 = (count?.data[2] || 0).toString(2);

        bits_2 = bits_2.padStart('8', '0');
        bits_1 = bits_1.padStart('8', '0');
        bits_0 = bits_0.padStart('8', '0');

        count = bits_2 + bits_1 + bits_0;

        return parseInt(count, 2);
    }

    useEffect(() => {
        (async () => {
            let count = await updateCount(index);
            setQty(count);
        })();
    }, [index]);

    const sold_out = qty_left >= qty;

    const badges_tt = [
        "This is an upgrade for Amoeboy.",
        "This is from a community creator."
    ];

    return (
        <div
            className={'relative rounded-sm cursor-pointer bg-transp inline-flex items-center flex-col mb-8 mr-4 ml-4 pb-4 max-w-xs border-2 border-opacity-20 border-medium' + (true ? '': '')}
            onClick={() => { mintItem(index); }}
        >
            <div className='mr-4 mt-4 absolute right-0'>
                {(((badges || 0) & 1) === 1) &&
                    <Badge tooltip={badges_tt[0]} name="upgrade"/>
                }
                {(((badges || 0) & 2) === 2) &&
                    <Badge className='-mt-1' tooltip={badges_tt[1]} name="highlight.svg"/>
                }
            </div>
            <p className='mb-2'>
                <Img className=' rounded-sm' name={image} height={256} />
            </p>
            <p className='text-light text-lg italic mb-2'>{name}</p>
            <ItemDesc>
                <>
                    <span className='w-20 flex'>
                        <span className='text-light text-sm mx-auto'>$M</span>
                    </span>
                    <span className='w-10 flex'>
                        <span className='mx-auto'>{p}</span>
                    </span>
                </>
            </ItemDesc>
            <ItemDesc>
                {sold_out && <span className='text-light'>'Sold Out!'</span>}
                {!sold_out && 
                    <>
                        <span className='w-20 flex'>
                            <span className='text-light mx-auto'>Remaining</span>
                        </span>
                        <span className='w-10 flex'>
                            <span className='mx-auto'>{qty - qty_left}</span>
                        </span>
                    </>}
            </ItemDesc>
        </div>
    )
}

function ItemDesc(props) {
    return (
        <p className='w-4/5 flex items-center justify-between'>
            {props.children}
        </p>
    )
}

function Badge({tooltip, name, className}) {
    const [hover, setHover] = useState(false);

    return (
        <span className='inline-block'
              onMouseEnter={() => setHover(true) }
              onMouseLeave={() => setHover(false)} >
            {hover &&
                <span className='absolute -top-10 -right-40 z-10 w-64 bg-black  pt-2 pb-2 text-center rounded-sm'>
                    {tooltip}
                </span>
            }
            <Img
                className={(className || '') + ' opacity-80 animate-sbounce float-right'}
                name={name}
                height={36}
                width={36}/>
        </span>
    )
}

function mintItem(mint_ac) {
    if (!window.wallet) {
        window.wallet_cb = () => { mintItem(mint_ac) }
        setModal();
        return;
    }
}

function mintMarketItem() {
    let contents = (
        <div>Hey</div>
    )

    flux.dispatch('modal/setOpen', true);
    flux.dispatch('modal/setContent', contents);
}
