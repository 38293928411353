import { setModal } from '../web3/connectButton';
import { getTokenList } from '../web3/getTokenList';
import * as sw3 from '@solana/web3.js';
import { useState, useEffect, useRef } from 'react';
import { Button, Img } from '../baseComponents.js';
import flux from '@aust/react-flux';

export function TransferPage() {
  const [recipient, setRecipient] = useState('');
  const [conf, setConf] = useState('');
  const tokens = flux.tokens.useState('tokens');
  const holder = flux.tokens.useState('holder');

  if (!holder) {
    //
    return <div>You are not a holder!</div>;
  }

  return (
    <>
      <div className="flex items-start flex-col justify-evenly w-full">
        <div className="flex flex-col justify-center items-center w-full ">
          <div>
            <Button
              text="Select All"
              onClick={() => {
                Array.from(
                  document.querySelectorAll(
                    'div[data-mint][data-selected="0"]',
                  ),
                ).forEach((e) => e.click());
              }}
            />
            <Button
              text="Deselect All"
              onClick={() => {
                Array.from(
                  document.querySelectorAll(
                    'div[data-mint][data-selected="1"]',
                  ),
                ).forEach((e) => e.click());
                //
              }}
            />
          </div>
          <div className="w-full">
            {tokens.map((e) => {
              return (
                <div className="inline-block text-center ml-2 mt-2">
                  <NFT metadata={e} />
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex items-center flex-col w-48ch">
          <span>Recipient</span>
          <input
            className="text-black w-full text-center mt-2 h-8"
            type="text"
            onChange={(el) => {
              setRecipient(el.target.value);
            }}
          />
          <span className="mt-2">Confirm Address</span>
          <input
            className="text-black w-full text-center mt-2 h-8"
            type="text"
            onChange={(el) => {
              setConf(el.target.value);
            }}
          />
        </div>
        <Button
          className="mt-4 mr-8"
          text="Transfer"
          onClick={() => {
            if (recipient.length < 43) {
              flux.dispatch('notices/error', 'Enter a wallet.');
            } else if (recipient !== conf) {
              flux.dispatch('notices/error', "Confirmed wallet doesn't match.");
            } else {
              const to_transfer = Array.from(
                document.querySelectorAll('div[data-mint][data-selected="1"]'),
              ).map((e) => {
                return new sw3.PublicKey(e.dataset.mint);
              });
              transferNFTs(to_transfer, recipient);
            }
          }}
        />
      </div>
    </>
  );
}

async function transferNFTs(mints, recipient) {
  const token_program = new sw3.PublicKey(
    'TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA',
  );
  const associated_program = new sw3.PublicKey(
    'ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL',
  );
  const payer_pkey = new sw3.PublicKey(window.wallet.publicKey.toBase58());
  const recipient_key = new sw3.PublicKey(recipient);
  const meeb = new sw3.PublicKey(
    'AMBTaiqVukuex8ap2BpFviPBcPxZyPvAMYQsrXJh8MjU',
  );

  try {
    flux.dispatch('loading/open', true);

    let base_accounts = [];
    base_accounts[0] = { pubkey: payer_pkey, isSigner: true, isWritable: true };
    base_accounts[1] = {
      pubkey: token_program,
      isSigner: false,
      isWritable: false,
    };
    base_accounts[2] = {
      pubkey: recipient_key,
      isSigner: false,
      isWritable: false,
    };

    let raw_txs = [];
    let count = 0;
    let transaction = new sw3.Transaction();
    let accounts = Array.from(base_accounts);

    for (let i = 0, l = mints.length; i < l; i++) {
      let largest = (await window.connection.getTokenLargestAccounts(mints[i]))
        .value;
      let payer_token = largest[0].address;

      // cant transfer this type. woops
      if (largest[1] !== undefined && largest[1].amount > 0) {
        continue;
      }

      accounts.push({ pubkey: payer_token, isSigner: false, isWritable: true });

      if (accounts.length == 27) {
        transaction.add(
          new sw3.TransactionInstruction({
            keys: accounts,
            programId: meeb,
            data: new Uint8Array([1, accounts.length - 3]),
          }),
        );

        raw_txs.push(transaction);

        transaction = new sw3.Transaction();
        accounts = Array.from(base_accounts);
      }
      count++;
    }

    if (accounts.length >= 3) {
      transaction.add(
        new sw3.TransactionInstruction({
          keys: accounts,
          programId: meeb,
          data: new Uint8Array([1, accounts.length - 3]),
        }),
      );

      raw_txs.push(transaction);
    }

    const rbh = (await window.connection.getRecentBlockhash()).blockhash;
    for (let i = 0, l = raw_txs.length; i < l; i++) {
      const tx = raw_txs[i];
      tx.setSigners(payer_pkey);

      tx.recentBlockhash = rbh;
      tx.feePayer = payer_pkey;
    }
    const signed_txs = await window.wallet.signAllTransactions(raw_txs);

    const promises = signed_txs.map((e) => {
      return sw3.sendAndConfirmRawTransaction(
        window.connection,
        e.serialize(),
        {
          commitment: 'confirmed',
          skipPreflight: false,
        },
      );
    });

    return Promise.all(promises)
      .then((e) => {
        flux.dispatch('notices/success', 'Success!');
      })
      .catch((e) => {
        console.log(e);
        const logs = e?.logs;
        let error = 'Unknown error occurred.';

        if (logs && logs.length) {
          error = logs[logs.length - 3].split(' ').splice(2).join(' ');
        } else if (e.message.indexOf('Attempt to debit') > -1) {
          error = 'Your wallet may be empty!';
        }

        if (error.indexOf('0x1') > -1) {
          error = 'Not enough Solana!';
        }

        flux.dispatch('loading/open', false);
        flux.dispatch('page/change', 'massTransfer', true);
        getTokenList();
        throw error;
      });
  } catch (e) {
    console.log(e);
    flux.dispatch('loading/open', false);
    flux.dispatch('page/change', 'massTransfer', true);
    getTokenList();
  }
}

async function asyncTxns(txs) {
  let bh = (await window.connection.getRecentBlockhash()).blockhash;
  txs = txs.map((e) => {
    e.recentBlockhash = bh;
    e.feePayer = window.wallet.publicKey;

    return window.wallet.signTransaction(e);
  });

  txs = await Promise.all(txs)
    .then((res) => {
      res = res.map((e) => {
        return sw3.sendAndConfirmRawTransaction(
          window.connection,
          e.serialize(),
          { commitment: 'finalized', skipPreflight: true },
        );
      });
      return res;
    })
    .catch((e) => {
      throw e;
    });

  return Promise.all(txs).catch((e) => {
    throw e;
  });
}

function NFT({ metadata }) {
  const [isExcluded, exclude] = useState(false);

  return (
    <div
      key={metadata.data.name}
      data-mint={new sw3.PublicKey(metadata.mint).toBase58()}
      data-selected={isExcluded ? 0 : 1}
      onClick={() => {
        exclude(!isExcluded);
      }}
      className={
        (isExcluded ? 'opacity-50 ' : ' opacity-90 ') +
        ' rounded-sm select-none w-full cursor-pointer p-1 border-2 border-slate-400 max-w-32'
      }
    >
      <span>{metadata.data.name}</span>
    </div>
  );
}
