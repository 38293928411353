import flux from '@aust/react-flux';
import { Img } from './baseComponents';

export function Modal(props) {
  const open = flux.modal.useState('open');
  const content = flux.modal.useState('content');

  let closeNice = () => {
    setTimeout(() => {
      flux.dispatch('modal/setOpen', false);
    }, 150);
  };

  const opacity = !open ? 'opacity-0 z-0 pointer-events-none' : 'z-20';

  return (
    <div id="modal_stage" className={'transition-opacity ' + opacity}>
      <div
        id="modal"
        className={
          props.className +
          ' mx-10 w-1/6 p-2 flex flex-col rounded-sm shadow-md'
        }
      >
        <span
          className="absolute self-end w-2 select-none cursor-pointer mr-2"
          onClick={() => {
            closeNice();
          }}
        >
          X
        </span>
        <span className="mx-auto w-auto mt-2">
          <Img name="logo" className="h-24 rounded-sm" />
        </span>
        <div className="flex flex-col align-center flex-grow p-2">
          {content}
        </div>
      </div>
    </div>
  );
}
