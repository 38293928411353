import * as sw3 from '@solana/web3.js';
import flux from '@aust/react-flux';
import { Button, Img } from '../baseComponents';
import { useEffect, useState } from 'react';
import Wallet from '@project-serum/sol-wallet-adapter';
import { getTokenList } from './getTokenList';

export function ConnectButton({ className, real_text, cb }) {
  let text = '';
  const modal_complete = flux.modal.useState('complete');
  if (real_text === undefined) {
    text = flux.connectButton.useState('text');
  } else {
    text = real_text;
  }
  const [clicked, clickedOn] = useState(false);

  useEffect(() => {
    if (cb && clicked === true) {
      flux.dispatch('modal/setCb', cb);
    }
  }, [cb, clicked]);

  if (!real_text && text !== 'Connect Wallet') {
    return <Button onClick={disconnect} className={className} text={text} />;
  } else {
    return (
      <Button
        onClick={() => {
          clickedOn(true);
          setModal();
        }}
        className={className}
        text={text}
      />
    );
  }
}

async function disconnect() {
  await window.wallet.disconnect();

  flux.dispatch('connectButton/Text', 'Connect Wallet');
}

export function setModal() {
  const providers = [
    {
      name: 'Phantom',
      logo: <Img height={32} width={32} name="phantom" />,
      cb: connectPhantom,
    },
    {
      name: 'Sollet',
      logo: <Img height={32} width={32} name="sollet" />,
      cb: connectSollet,
    },
    {
      name: 'Solflare',
      logo: <Img height={32} width={32} name="solflare" />,
      cb: connectSolflare,
    },
  ];

  const contents = (
    <>
      {providers.map((e) => {
        return (
          <span
            onClick={() => {
              e.cb();
            }}
            className="active:scale-98 bg-transp relative rounded-sm shadow-sm cursor-pointer p-2 flex justify-between mr-auto ml-auto mb-2 mt-2 w-full align-center"
            key={e.name}
          >
            {e.logo}
            <span className="select-none flex items-center justify-center w-32 text-gray-100 text-xl">
              <span>{e.name}</span>
            </span>
          </span>
        );
      })}
    </>
  );

  flux.dispatch('modal/setOpen', true);
  flux.dispatch('modal/setContent', contents);
}

async function connectPhantom() {
  window.solana.connect();
  window.wallet = window.solana;

  connectionEvents();
}

async function connectSolflare() {
  connectProvidedWallet('https://solflare.com/provider');
}

async function connectSollet() {
  connectProvidedWallet('https://www.sollet.io');
}

async function connectProvidedWallet(provider) {
  window.sw3 = sw3;

  window.wallet = new Wallet(provider);
  connectionEvents();
  await window.wallet.connect();
}

async function connectionEvents() {
  window.wallet.on('connect', () => {
    let pk = window.wallet.publicKey.toBase58();
    let pretty =
      pk.substr(0, 4) +
      '...' +
      pk.split('').reverse().join('').substr(0, 4).split('').reverse().join('');

    if (window.wallet_cb) {
      window.wallet_cb();
    }
    flux.dispatch('connectButton/Text', pretty);
    flux.dispatch('modal/setOpen', false);
    flux.dispatch('modal/complete', true);

    const cb = flux.modal.selectState('cb');
    if (cb) {
      cb();
      flux.dispatch('modal/setCb', null);
    }

    setTimeout(() => {
      flux.dispatch('modal/complete', false);
    }, 200);
    getTokenList();
  });

  window.wallet.on('disconnect', disconnectedWallet);
}

async function disconnectedWallet() {
  window.wallet = null;
  //flux.dispatch('page/change', '');
}
